/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackGtagEvent } from 'utils';

import { QUERIES, SearchInput, useInitialPaginationFetch } from '@marapp/earth-shared';

import {
  recordFilterSelection,
  recordSearchLayers,
  recordSelectSearchResult,
} from '../../analytics/gtag';
import { useAuth0 } from '../../auth/auth0';
import BackToLocation from '../../components/back-to-location';
import FilterBy from '../../components/filter-by';
import InfiniteList from '../../components/infinite-list';
import ListItem from '../../components/list-item';
import SidebarLayoutSearch from '../../components/sidebar/sidebar-layout-search';
import { useLayers } from '../../fetchers';
import { EPanels } from '../../modules/sidebar/model';
import AnalyticService from '../../services/AnalyticService';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.grey['600'],
  },
}));

interface IProps {
  selected: boolean;
  children: any;
  basemap?: string;
  basemaps?: Array<{
    slug: string;
    name: string;
    background: string;
    id: string;
  }>;
  nextPageCursor?: string;
  setMapStyle?: (slug: any) => void;
  mapStyle?: any;
  toggleLayer?: (any) => void;
  layers?: {
    loading?: boolean;
    search?: any;
    active?: string[];
    listActive?: any[];
    results?: any[];
    nextPageCursor?: string;
  };
  open?: boolean;
  layersPanel?: boolean;
  panel?: string;
  panelExpanded?: boolean;
  locationName?: string;
  locationOrganization?: string;

  setSidebarPanel?: (value: any) => void;
  setSidebarPanelExpanded?: (value: boolean) => void;
  setLayersSearch?: (value: any) => void;
  setPlacesSearch?: (value: any) => void;
  resetLayersActive?: () => void;
  nextLayersPage?: (value: any) => void;
  setLayersSearchOpen?: (value: boolean) => void;
}

/**
 * Takes an array of filter categories and sorts so subcategories are listed in a custom order.
 */
function customSort(filterCategories: any[], subcategoryOrder: string[]) {
  return [...filterCategories].sort((a, b) => {
    if (
      a.path.length === b.path.length &&
      JSON.stringify(a.path.slice(0, a.path.length - 1)) ===
        JSON.stringify(b.path.slice(0, b.path.length - 1))
    ) {
      const subcategoryA = subcategoryOrder.find((subcategory) => a.value.endsWith(subcategory));
      const subcategoryB = subcategoryOrder.find((subcategory) => b.value.endsWith(subcategory));
      if (subcategoryA && subcategoryB) {
        return subcategoryOrder.indexOf(subcategoryA) - subcategoryOrder.indexOf(subcategoryB);
      } else if (subcategoryA) {
        return -1;
      } else if (subcategoryB) {
        return 1;
      }
    }
    return a.value.localeCompare(b.value);
  });
}

const Layers = (props: IProps) => {
  const {
    selected,
    children,
    layers,
    panelExpanded,
    setSidebarPanel,
    setSidebarPanelExpanded,
    locationName,
    locationOrganization,
    setLayersSearch,
    setPlacesSearch,
    setLayersSearchOpen,
  } = props;
  const { search, active } = layers;
  const showX = !!search.search;
  const showFilter = !selected || panelExpanded;
  const showBack = selected && panelExpanded;
  const classes = useStyles();
  const { t } = useTranslation();
  const [availableFilters, setAvailableFilters] = useState(null);
  const { selectedGroup } = useAuth0();
  const fetchedLayers = useLayers(QUERIES.LAYER.getPrimaryFiltered(search.search, search.filters));
  const { data: layersData, awaitMore, nextPage, isValidating } = fetchedLayers;

  const { totalResults } = useInitialPaginationFetch(search, fetchedLayers, (newFetch) => {
    const orderedFilters = {
      ...(newFetch?.meta?.filters || {}),
      ...(newFetch?.meta?.filters?.category && {
        category: customSort(newFetch.meta.filters.category, [
          'Headline Indicators',
          'Component Indicators',
          'Complementary Indicators',
        ]),
      }),
    };
    setAvailableFilters(orderedFilters);
  });

  const handleChange = (newValue) => {
    setLayersSearch({ search: newValue });
    recordSearchLayers(newValue);
  };

  const handleBack = () => {
    if (selected) {
      setPlacesSearch({ search: locationName });
      setSidebarPanel(EPanels.PLACES);
    }
    setSidebarPanelExpanded(false);
  };

  const handleReset = () => {
    setLayersSearch({
      search: '',
      filters: {},
      open: false,
    });
  };

  const onToggleLayer = (layer, labels) => {
    const { toggleLayer } = props;

    const isCurrentlyActive = !!active.find((slug) => slug === layer.slug);
    if (!isCurrentlyActive) {
      AnalyticService.addClickCount('layer', layer.id);
      trackGtagEvent('select_content', { content_type: 'layer', item_id: layer.slug });
      recordSelectSearchResult('Layer', labels.join(', '), layer.slug);
    }

    toggleLayer(layer);
  };

  const onFiltersChange = (filters) => {
    setLayersSearch(filters);
    if (filters.filters && filters.filters.category) {
      recordFilterSelection('Layer', filters.filters.category);
    }
  };

  return (
    <SidebarLayoutSearch
      fixedContent={
        <>
          <Box p={2} className={classes.searchContainer}>
            <SearchInput
              value={search.search}
              placeholder={t('search layers')}
              onChange={handleChange}
              onReset={handleReset}
              onFocus={() => setSidebarPanelExpanded(true)}
              showResetButton={showX}
            />
          </Box>

          {showFilter && (
            <FilterBy
              type="Layer"
              open={search.open}
              onOpenToggle={setLayersSearchOpen}
              onChange={onFiltersChange}
              filters={search.filters}
              availableFilters={availableFilters || {}}
              renderLive={false}
            />
          )}
          {showBack && (
            <BackToLocation
              onClick={handleBack}
              location={locationName}
              organization={locationOrganization}
            />
          )}
        </>
      }
    >
      {children && (
        <Box mt={1}>
          <Paper className="marapp-qa-other" square={true}>
            {children}
          </Paper>
        </Box>
      )}

      <Box>
        {(!selected || panelExpanded) && (
          <Paper className="marapp-qa-other" square={true}>
            <InfiniteList
              title={t('Layers')}
              data={layersData}
              totalResults={totalResults}
              search={search.search}
              isValidating={isValidating}
              onNextPage={nextPage}
              awaitMore={awaitMore}
            >
              {(layer) => {
                // Only list layer categories up to 1 level of subcategory
                const mainCategories = layer.category.filter(
                  (category) => category.split('/').length <= 2
                );
                const labels = mainCategories.filter(
                  (category) =>
                    !mainCategories.some(
                      (otherCategory) =>
                        otherCategory !== category && otherCategory.startsWith(category)
                    )
                );
                return (
                  <ListItem
                    hint={layer.$searchHint}
                    title={layer.name}
                    noWrap={false}
                    flipBySlash={true}
                    active={!!active.find((slug) => slug === layer.slug)}
                    key={`${layer.slug}-${layer.organization}`}
                    onClick={debounce(() => onToggleLayer(layer, labels), 200)}
                    organization={selectedGroup.length > 1 && layer.organization}
                    labels={labels}
                  />
                );
              }}
            </InfiniteList>
          </Paper>
        )}
      </Box>
    </SidebarLayoutSearch>
  );
};

export default Layers;
